import type { ModifyCartItemByDecoratedProduct } from '../model';
import { isDefined } from '~/utils/guards/isDefined';

/**
 * Creates a single entry inside an analytics event.
 *
 * @see https://gcp.baslerweb.com/jira/browse/WEB2-1695
 *
 * @param decoratedProduct The decorated product to use.
 * @param quantity The quantity to use.
 */
function transformItem({
  decoratedProduct,
  quantity
}: ModifyCartItemByDecoratedProduct) {
  return {
    item_id: decoratedProduct.sku, // sku_107822
    item_name: decoratedProduct.modelName, // a2A1920-51gmBAS - Basler ace 2
    item_category: decoratedProduct.category, // Area scan camera
    item_category_id: decoratedProduct.categoryId, // Area scan camera
    price: decoratedProduct.price, // 15.5
    quantity, // 1 -- always 1 in case of view_item
    delivery_time:
      decoratedProduct.showShippingTime &&
      isDefined(decoratedProduct.shippingTimeText)
        ? decoratedProduct.shippingTimeText // 4-5 weeks
        : 'Not available'
  };
}

export function createEcommerceTrackingData({
  cartItems,
  source = 'website'
}: {
  cartItems: ReadonlyArray<ModifyCartItemByDecoratedProduct>;
  source?: string;
}) {
  const [firstItem] = cartItems;
  if (!firstItem) {
    return;
  }

  return {
    currency: firstItem.decoratedProduct.currencySign, // $
    value: cartItems.reduce((collector, { decoratedProduct, quantity }) => {
      const price = Number(decoratedProduct.price);
      if (quantity <= 0 || isNaN(price)) {
        return collector;
      }

      return collector + quantity * price;
    }, 0), // 15.5
    items: cartItems.map((item) => transformItem(item)),
    ecom_source: source // @see https://gcp.baslerweb.com/jira/browse/WEB2-1724
  };
}
