import type { Ref } from 'vue';
import type { INavigation } from '~/lib/ContentfulService';

export default defineNuxtPlugin(() => {
  const secondaryNavigation: Ref<INavigation | null> =
    useState<INavigation | null>('items', () => null);

  return {
    provide: {
      secondaryNavigation
    }
  };
});
