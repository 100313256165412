
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93om8vqvcmCjqS8k76Q1BYSXhTRPoYB8IHEd8vUNq0ZjgMeta } from "/app/pages/[locale]/_technical/html/[id].vue?macro=true";
import { default as _91_46_46_46slug_93A98ud12YRjhrN1815xn2KikjkqvUoEo02Jlu4wJCJFAMeta } from "/app/pages/[locale]/[...slug].vue?macro=true";
import { default as _91id_93vpLJ1U_b77ryWC_i3mSDwP5lJYQfSqFXl0_JzrnQD6cMeta } from "/app/pages/[locale]/id/[id].vue?macro=true";
import { default as _91urlKey_93XZEuPyOLNtiVFyAn74rNrpLthtk0Y6GC6VoKty3VwHQMeta } from "/app/pages/[locale]/shop/[urlKey].vue?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "locale-_technical-html-id",
    path: "/:locale()/_technical/html/:id()",
    meta: _91id_93om8vqvcmCjqS8k76Q1BYSXhTRPoYB8IHEd8vUNq0ZjgMeta || {},
    component: () => import("/app/pages/[locale]/_technical/html/[id].vue")
  },
  {
    name: "locale-slug",
    path: "/:locale()/:slug(.*)*",
    component: () => import("/app/pages/[locale]/[...slug].vue")
  },
  {
    name: "locale-id-id",
    path: "/:locale()/id/:id()",
    component: () => import("/app/pages/[locale]/id/[id].vue")
  },
  {
    name: "locale-shop-urlKey",
    path: "/:locale()/shop/:urlKey()",
    component: () => import("/app/pages/[locale]/shop/[urlKey].vue")
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/auth/login",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/auth/logout",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  }
]