import { v } from 'h3-valibot';
import type { LOCALE_CODE } from '~/lib/ContentfulService';

export const parseContentfulLocale = v.pipe(
  v.string(),
  v.transform(
    // XXX: actual validation of contentful locale via `refine`? access
    //  to loaded locales necessary.
    (val) => val as LOCALE_CODE
  )
);
