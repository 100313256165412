import type { GalleryItem } from '@hypercodestudio/basler-components/dist/components/modules/ProductDetailGallery.vue';
import { getIndexCustomAttributes } from './DecoratedProduct';
import { getCustomAttributeValue } from './getCustomAttributeValueFromMetadataCode';
import type { GenerateImageObjectOptions } from './generateImageObject';
import { generateImageObject } from './generateImageObject';
import type {
  CommonProductDetailFragment,
  CommonProductFragment,
  CustomAttributeV2Fragment
} from '~/lib/Shop/generated/schema';
import {
  CAMERA_CATEGORY,
  CAMERA_SERIES_CODE,
  MODEL_NAME,
  PRODUCT_IMAGE
} from '~/utils/shop/constants';
import { isDefined } from '~/utils/guards/isDefined';
import type { ProductImageType } from '~/types/ProductImageType';
import { isAttributeValue } from '~/utils/shop/guards/isAttributeValue';
import { extractCustomAttributes } from '~/utils/shop/extractCustomAttributes';

export function getProductImages(
  product: CommonProductFragment | CommonProductDetailFragment
): ProductImageType[] {
  const customAttributes = extractCustomAttributes(product);
  const productImages =
    getProductImagesFromCustomAttributes(customAttributes) ?? [];

  return [...productImages].sort(
    (a, b) => Number(a.productimagesorting) - Number(b.productimagesorting)
  );
}

export function getProductImage(
  product: CommonProductFragment | CommonProductDetailFragment,
  options: GenerateImageObjectOptions = {}
): GalleryItem[] {
  const mappedCustomAttributes = getIndexCustomAttributes(
    extractCustomAttributes(product)
  );

  const altTag = [
    getCustomAttributeValue(mappedCustomAttributes[CAMERA_SERIES_CODE]),
    getCustomAttributeValue(mappedCustomAttributes[MODEL_NAME]),
    getCustomAttributeValue(mappedCustomAttributes[CAMERA_CATEGORY])
  ].join(' ');

  const productImages = getProductImages(product);

  if (productImages.length > 0) {
    return productImages.map((img) => ({
      media: generateImageObject(
        {
          altTag,
          url: img.medialink,
          width: options.width,
          height: options.height
        },
        options
      )
    }));
  }

  return getDefaultImage(altTag, options);
}

function getProductImagesFromCustomAttributes(
  customAttributes: ReadonlyArray<CustomAttributeV2Fragment>
): ProductImageType[] | undefined {
  const hit = customAttributes.find((x) => x?.code === PRODUCT_IMAGE);

  const productImage = isAttributeValue(hit) ? hit?.value : undefined;

  if (isDefined(productImage) && productImage !== '') {
    try {
      const parsedImage: ProductImageType[] = JSON.parse(productImage);
      const currentImgs = Object.values(parsedImage).filter(
        (img) => img.medialink !== ''
      );

      if (currentImgs.length > 0) {
        return currentImgs;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(
        'failed to parse product images from json:',
        productImage,
        error
      );
    }
  }
}

function getDefaultImage(
  altTag: string,
  options: GenerateImageObjectOptions
): GalleryItem[] {
  const { $globalPageSettings } = useNuxtApp();
  const url = $globalPageSettings.value?.defaultProductImage;

  if (url) {
    return [
      {
        media: generateImageObject(
          {
            altTag,
            url,
            width: options.width,
            height: options.height
          },
          options
        )
      }
    ];
  }

  return [];
}
