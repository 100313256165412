import { acceptHMRUpdate, defineStore } from 'pinia';
import type {
  CartType,
  ModifyCartItem,
  UpdateCartItem,
  CartActionModel,
  AddProductsOptions
} from './model';
import type { CartFragment } from '~/lib/Shop/generated/schema';
import { modifyCartItemSchema } from './model';
import { useCartLink } from './useCartLink';
import { extractError } from '~/utils/extractError';
import type {
  AddProductsToCartMutationVariables,
  UpdateProductsInCartMutationVariables,
  CartItemUpdateInput,
  CartUserErrorsFragment
} from '~/lib/Shop/generated/schema';
import { useCartCookie } from '~/composables/useCartCookie';
import type { CartItemPutResponseModel } from '~/server/api/magento/cart/item.put';
import type { UpdateCartResponseModel } from '~/server/api/magento/cart/index.put';
import type { DeleteCartItemResponseModel } from '~/server/api/magento/cart/item.delete';
import type { GetCartResponseModel } from '~/server/api/magento/cart/index.post';
import { usePdpConfiguration } from '~/composables/usePdpConfiguration';
import { useTriggerAddToCartEvent } from '~/stores/cartStore/useTriggerAddToCartEvent';
import { v } from 'h3-valibot';

function extractUserErrors(
  userErrors: ReadonlyArray<CartUserErrorsFragment> | undefined | null
) {
  if (!userErrors) {
    return;
  }

  const errors = userErrors.map(
    (error: CartUserErrorsFragment) => error.message
  );

  return errors.length === 0 ? undefined : errors;
}

function retrieveProductSku(cartItem: ModifyCartItem): string {
  if ('sku' in cartItem) {
    return cartItem.sku;
  }

  return cartItem.decoratedProduct!.sku!;
}

/**
 * Provides a store for the shopping cart.
 * GUI Related operations should be moved to a separate store / component /
 * composable and depend on this store.
 */
export const useCartStore = defineStore('shop-cart', () => {
  const { $shopStore, $locale } = useNuxtApp();
  const lastAction = ref<CartActionModel | null>(null);
  const loading = ref<boolean>(false);
  const carts = ref<Record<CartType, CartFragment | null>>({
    cart: null,
    requestCart: null
  });
  const activeCartType = ref<CartType>('cart');

  const _state = {
    lastAction,
    loading,
    carts,
    activeCartType
  };

  const activeCart = computed(() => {
    return carts.value[activeCartType.value];
  });

  const activeCartItemCount = computed<number | undefined>(() => {
    // return `undefined` instead of 0
    return activeCart.value?.items?.length || undefined;
  });

  const currentCartLink = computed<string | undefined>(() => {
    return useCartLink(activeCartType.value);
  });

  const availableCarts = computed<Record<string, string | undefined | null>>(
    () => {
      return Object.fromEntries(
        Object.entries(cartIds.value).map(([type, storeCartIdMap]) => [
          type,
          storeCartIdMap?.[$shopStore.value]
        ])
      );
    }
  );

  const cartIds = computed(() => {
    return {
      // could use ref directly - but looses actual function to sync cookie
      cart: useCartCookie('cart', $locale.value).value,
      requestCart: useCartCookie('requestCart', $locale.value).value
    };
  });

  const shopBaseUrlMap = computed<Record<string, string | undefined>>(() => {
    const pdpConfiguration = usePdpConfiguration();

    return {
      default: pdpConfiguration.value?.defaultConfiguration?.baseUrl,
      ...Object.fromEntries(
        Object.entries(pdpConfiguration.value?.configurationByLocale ?? {}).map(
          ([key, value]) => [key, value.baseUrl]
        )
      )
    };
  });

  const profileLink = computed<string>(() => {
    return `${
      shopBaseUrlMap.value[$locale.value] ?? shopBaseUrlMap.value.default
    }/${$shopStore.value}/customer/account/login/`;
  });

  /**
   * Adding a product which is already in the cart will increase the
   * amount accordingly.
   * Performs internal error handling.
   *
   * @param cartItems The items to add.
   * @param cartType The cart type to use.
   * @param options Additional options to use.
   */
  async function addProducts(
    cartItems: ReadonlyArray<ModifyCartItem>,
    cartType: CartType,
    options?: AddProductsOptions
  ) {
    const logger = useLogger();
    if (!cartItems.length) {
      logger.warn('cartStore: no elements defined to add to cart - aborting.');

      return;
    }

    const parsedItems = cartItems.map((item) =>
      v.parse(modifyCartItemSchema, item)
    );

    loading.value = true;

    const action: CartActionModel = {
      type: 'addItems'
    };

    try {
      // the last modified cart should be active
      // @see https://gcp.baslerweb.com/jira/browse/WEB2-1577
      activeCartType.value = cartType;
      const addItemResponse = await $fetch<CartItemPutResponseModel>(
        '/api/magento/cart/item',
        {
          method: 'PUT',
          query: {
            store: $shopStore.value,
            locale: $locale.value,
            type: cartType
          },
          body: {
            cartItems: parsedItems.map((cartItem) => ({
              sku: retrieveProductSku(cartItem),
              quantity: cartItem.quantity,
              config_id: cartItem?.config_id ?? ''
            }))
          } satisfies Pick<AddProductsToCartMutationVariables, 'cartItems'>
        }
      );
      if (addItemResponse) {
        carts.value[cartType] = addItemResponse.cart;
        // XXX: proper typing
        action.error = extractUserErrors(addItemResponse.user_errors as never);
      }

      useTriggerAddToCartEvent(
        parsedItems,
        cartType,
        carts.value[cartType]!.id,
        $shopStore.value,
        $locale.value,
        options
      );
    } catch (e) {
      action.error = extractError(e);
    } finally {
      loading.value = false;
      lastAction.value = action;
    }
  }
  /**
   * Updates the given cart with the given items.
   * If no cart type is given, the current active cart is used.
   * Performs internal error handling.
   *
   * @param cartItems The update items.
   * @param cartType The cart type to use.
   */

  async function updateProducts(
    cartItems: ReadonlyArray<UpdateCartItem>,
    cartType: CartType
  ) {
    const logger = useLogger();
    if (!cartItems.length) {
      logger.warn(
        'cartStore: no elements defined to update in cart - aborting.'
      );

      return;
    }

    loading.value = true;

    const action: CartActionModel = {
      type: 'updateItems'
    };

    try {
      // the last modified cart should be active
      // @see https://gcp.baslerweb.com/jira/browse/WEB2-1577
      activeCartType.value = cartType;
      carts.value[cartType] = await $fetch<UpdateCartResponseModel>(
        '/api/magento/cart',
        {
          method: 'PUT',
          query: {
            store: $shopStore.value,
            locale: $locale.value,
            type: cartType
          },
          body: {
            cartItems: cartItems.map(
              (cartItem) =>
                ({
                  cart_item_uid: cartItem.uid,
                  quantity: cartItem.quantity
                } satisfies CartItemUpdateInput)
            )
          } satisfies Pick<UpdateProductsInCartMutationVariables, 'cartItems'>
        }
      );
    } catch (e) {
      action.error = extractError(e);
    } finally {
      lastAction.value = action;
      loading.value = false;
    }
  }

  /**
   * Removes the given product from the given cart type.
   * If no cart type is given, the current active cart is used.
   * Performs internal error handling.
   *
   * @param productUid The product uid to remove.
   * @param cartType The cart type to use.
   */

  async function removeFromCart(productUid: string, cartType?: CartType) {
    const usedCartType = cartType ?? activeCartType.value;
    loading.value = true;
    const action: CartActionModel = {
      type: 'removeItem'
    };
    try {
      // the last modified cart should be active
      // @see https://gcp.baslerweb.com/jira/browse/WEB2-1577
      activeCartType.value = usedCartType;
      carts.value[usedCartType] = await $fetch<DeleteCartItemResponseModel>(
        '/api/magento/cart/item',
        {
          method: 'DELETE',
          query: {
            store: $shopStore.value,
            locale: $locale.value,
            type: usedCartType,
            cartItemUid: productUid
          }
        }
      );
    } catch (e) {
      action.error = extractError(e);
    } finally {
      lastAction.value = action;
      loading.value = false;
    }
  }

  /**
   * (Re)loads the given cart type.
   * This ensures, that a cart actually exists (if not, a new one
   * is created).
   * If no cart type is given, the current active cart is used.
   * Performs internal error handling.
   *
   * @param type The cart type to load.
   */

  async function loadCart(type?: CartType) {
    const usedCartType = type ?? activeCartType.value;

    loading.value = true;
    const action: CartActionModel = {
      type: 'loadCart'
    };

    try {
      carts.value[usedCartType] = await $fetch<GetCartResponseModel>(
        '/api/magento/cart',
        {
          method: 'POST',
          query: {
            type: usedCartType,
            locale: $locale.value,
            store: $shopStore.value
          }
        }
      );
    } catch (e) {
      action.error = extractError(e);
    } finally {
      lastAction.value = action;
      loading.value = false;
    }
  }

  /**
   * Loads all available carts ({@link this.availableCarts}).
   * Performs a request per available {@link CartType}.
   */
  async function init() {
    await Promise.all(
      Object.entries(availableCarts.value).map(async ([type, cartId]) => {
        if (cartId) {
          await loadCart(type as CartType);
        }
      })
    );
    // display the cart which has actually items.
    // if no cart has "isActive" set - the empty dialog is displayed.
    const firstCartTypeWithItems = Object.entries(carts.value).find(
      ([_, cart]) => cart && cart.items && cart.items.length > 0
    )?.[0] as CartType | undefined;
    if (firstCartTypeWithItems) {
      activeCartType.value = firstCartTypeWithItems;
    }
  }

  return {
    _state,
    lastAction,
    loading,
    carts,
    activeCartType,
    activeCart,
    activeCartItemCount,
    currentCartLink,
    cartIds,
    availableCarts,
    shopBaseUrlMap,
    profileLink,
    addProducts,
    updateProducts,
    removeFromCart,
    loadCart,
    init
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
