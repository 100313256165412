import { v } from 'h3-valibot';
import { parseContentfulLocale } from '~/utils/validation/parseContentfulLocale';

export const geolocationCookieSchema = v.object({
  detectedCountry: v.optional(v.string()),
  detectedLocale: v.optional(parseContentfulLocale),
  /**
   * @example "en-US"
   */
  preferredLocale: v.optional(v.nullable(v.string())),
  /**
   * @example "EE"
   */
  preferredCountry: v.optional(v.nullable(v.string()))
});

export type GeoLocationCookieSchemaModel = v.InferOutput<
  typeof geolocationCookieSchema
>;
