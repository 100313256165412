import type { CartType } from './model';
import { useCartStore } from './cartStore';

/**
 * Creates a cart link using the given cart type.
 * This composable uses {@link useCartStore} and can
 * only be used in a setup function, plugin, or route middleware.
 *
 * @param type The cart type to use.
 */
export function useCartLink(type: CartType): string | undefined {
  const { $locale, $shopStore } = useNuxtApp();
  const cartStore = useCartStore();
  const url = useRequestURL();
  const cart = cartStore.carts[type];
  if (!cart) {
    return;
  }

  const urlSearchParams = new URLSearchParams([
    ['locale', $locale.value],
    ['store', $shopStore.value],
    ['type', type.toString()],
    // we need to add the cart id to avoid improper caching in CDN
    ['cartId', cart.id]
  ]);

  // we need an absolut url to avoid vue router pushes.
  return `${
    url.origin
  }/api/magento/cart/redirect/?${urlSearchParams.toString()}`;
}
