import { v } from 'h3-valibot';
import type { CartFragment } from '~/lib/Shop/generated/schema';
import { DecoratedProduct } from '~/utils/shop/DecoratedProduct';

export type CartType = 'cart' | 'requestCart';

const modifyCartItemBySku = v.object({
  quantity: v.number(),
  sku: v.string(),
  config_id: v.optional(v.string())
});
const modifyCartItemByDecoratedProduct = v.object({
  quantity: v.number(),
  config_id: v.optional(v.string()),
  decoratedProduct: v.instance(DecoratedProduct)
});

export type ModifyCartItemByDecoratedProduct = v.InferOutput<
  typeof modifyCartItemByDecoratedProduct
>;

export const modifyCartItemSchema = v.union([
  modifyCartItemBySku,
  modifyCartItemByDecoratedProduct
]);

export type ModifyCartItem = v.InferOutput<typeof modifyCartItemSchema>;

export type UpdateCartItem = {
  uid: string;
  quantity: number;
  config_id: string;
};

export type CartActionType =
  | 'addItems'
  | 'removeItem'
  | 'updateItems'
  | 'loadCart';

export type CartActionModel = {
  type: CartActionType;
  error?: string | string[] | null;
};

export type AddProductsOptions = {
  /**
   * The source the product was added from.
   * Used in analytic event. If not present, a default source
   * will be used.
   *
   * @see https://gcp.baslerweb.com/jira/browse/WEB2-2439
   */
  source?: string;
};

export type CartStoreState = {
  /**
   * @deprecated use `$shopStore` from `useNuxtApp` instead.
   */
  store: string;
  /**
   * @deprecated use `$locale` from `useNuxtApp` instead.
   */
  locale: string;
  lastAction: CartActionModel | null;
  /**
   * Loading indicator for __every__ async api action performed in this.
   * If a more detailed loading state is necessary, this could be moved to
   * a getter.
   */
  loading: boolean;
  /**
   * All available carts and **loaded** carts.
   * A cart might exist (cookie) but is not loaded. In that, the
   * value will be `null`!
   */
  carts: Record<CartType, CartFragment | null>;
  activeCartType: CartType;
};
