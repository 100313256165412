import type { CustomAttributeV2Fragment } from '~/lib/Shop/generated/schema';
import { isDefined } from '~/utils/guards/isDefined';
import { isAttributeSelectedOptions } from '~/utils/shop/guards/isAttributeSelectedOptions';
import { isAttributeValue } from '~/utils/shop/guards/isAttributeValue';

export function getCustomAttributeValue(
  customAttribute: CustomAttributeV2Fragment | undefined,
  options: { label: boolean } = { label: true }
): string | undefined {
  // some boolean attributes contain an value property
  let value;
  if (isAttributeSelectedOptions(customAttribute)) {
    value = customAttribute?.selected_options
      ?.map((entry) => (options?.label ? entry?.label : entry?.value))
      .filter(isDefined)
      .join(', ');
  }
  if (!value && isAttributeValue(customAttribute)) {
    value = customAttribute.value;
  }

  return value;
}

export function getCustomAttributeFirstValue(
  customAttribute: CustomAttributeV2Fragment | undefined,
  options: { label: boolean } = { label: true }
): string | undefined {
  // some boolean attributes contain an value property
  let value;
  if (isAttributeSelectedOptions(customAttribute)) {
    value = customAttribute?.selected_options
      ?.map((entry) => (options?.label ? entry?.label : entry?.value))
      .filter(isDefined)
      .find(isDefined);
  }
  if (!value && isAttributeValue(customAttribute)) {
    value = customAttribute.value;
  }
  return value;
}

export function getCustomAttributeArrayValue(
  customAttribute: CustomAttributeV2Fragment | undefined,
  options: { label: boolean } = { label: true }
): string[] | undefined {
  // some boolean attributes contain an value property
  if (isAttributeSelectedOptions(customAttribute)) {
    const filteredValues = customAttribute?.selected_options
      ?.map((entry) => (options?.label ? entry?.label : entry?.value))
      .filter(isDefined);

    if (filteredValues?.length) {
      return filteredValues;
    }
  }

  if (isAttributeValue(customAttribute)) {
    const enteredValue = customAttribute?.value;

    if (isDefined(enteredValue)) {
      return [enteredValue];
    }
  }

  return undefined;
}
