import type { WritableComputedRef } from 'vue';
import type { GeoLocationCookieSchemaModel } from '~/lib/GeolocationService/shared/schema';
import { geolocationCookieSchema } from '~/lib/GeolocationService/shared/schema';
import { v } from 'h3-valibot';

/**
 * Prefer access via {@link useGeoinformationStore}.
 *
 * @internal
 */
export function useGeolocationCookie(): WritableComputedRef<
  GeoLocationCookieSchemaModel | undefined
> {
  const name = useRuntimeConfig().public.detectedLocationCookieName;
  const cookie = useCookie<GeoLocationCookieSchemaModel | undefined>(name, {
    secure: true
    // can not use `decode` method, as `decode` is performed on *every* cookie
    // resulting in zod errors if schema validation is performed in decode.
    // therefore, we expose a computed value and a setter.
  });

  return computed<GeoLocationCookieSchemaModel | undefined>({
    get() {
      const result = v.safeParse(geolocationCookieSchema, cookie.value);

      if (result.success) {
        return result.output;
      }
    },
    set(newValue) {
      cookie.value = newValue;
    }
  });
}
