import type { Entry } from 'contentful';
import type GlobalComponents from '../.nuxt/components';
import type { GetComponentProps } from '~/types/GetComponentProps';
import type { CONTENT_TYPE } from '~/lib/ContentfulService';

type Components = typeof GlobalComponents;

type ContentfulComponentPropsShape = {
  item: Entry<unknown>;
};
// Filtered components, only allow components with matching Name and Props type shape
type ContentfulComponentMap = {
  [K in keyof Components as K extends `Contentful${string}`
    ? GetComponentProps<Components[K]> extends ContentfulComponentPropsShape
      ? K
      : never
    : never]: K;
};
type ContentfulComponentKeys = keyof ContentfulComponentMap;

const componentMap: Partial<Record<CONTENT_TYPE, ContentfulComponentKeys>> = {
  contentPage: 'ContentfulContentPage',
  contentPageMultiLocale: 'ContentfulContentPage',
  solutionsArticlePage: 'ContentfulSolutionsArticlePage',
  headline: 'ContentfulHeadline',
  textMedia: 'ContentfulTextMedia',
  badge: 'ContentfulBadge',
  spacer: 'ContentfulSpacer',
  tile: 'ContentfulTile',
  tiles: 'ContentfulTiles',
  customHtml: 'ContentfulCustomHTML',
  link: 'ContentfulLink',
  textImageTeaser: 'ContentfulTextImageTeaser',
  textImageTeaserList: 'ContentfulTextImageTeaserList',
  carouselSection: 'ContentfulCardCarouselSection',
  image: 'ContentfulImage',
  productSeriesPage: 'ContentfulProductSeriesPage',
  textTeaserCardList: 'ContentfulTextTeaserCardList',
  paragraph: 'ContentfulParagraph',
  video: 'ContentfulVideo',
  table: 'ContentfulTable',
  linksGroup: 'ContentfulLinksGroup',
  testimonialList: 'ContentfulTestimonialList',
  heroArticleSection: 'ContentfulHeroArticleSection',
  heroProductSection: 'ContentfulHeroProductSection',
  tagCloudContainer: 'ContentfulTagCloudContainer',
  tabSection: 'ContentfulTabsSection',
  ctaSection: 'ContentfulCtaSection',
  contentTabs: 'ContentfulContentTabs',
  columns: 'ContentfulColumns',
  accordionSection: 'ContentfulAccordionSection',
  tagOverviewSection: 'ContentfulTagOverviewSection',
  mediaTabs: 'ContentfulMediaTabs',
  animation: 'ContentfulAnimation',
  productList: 'ContentfulProductsListSection',
  technicalComponent: 'ContentfulTechnicalComponent',
  experience: 'ContentfulExperience',
  multiExperience: 'ContentfulMultiExperience'
};

export default componentMap;
