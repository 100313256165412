import { ContentfulLivePreview } from '@contentful/live-preview';

export default defineNuxtPlugin({
  name: 'init-contentful-live-preview',
  async setup() {
    const {
      public: { isPreviewEnabled }
    } = useRuntimeConfig();

    ContentfulLivePreview.init({
      locale: 'de-DE',
      enableInspectorMode: isPreviewEnabled,
      enableLiveUpdates: isPreviewEnabled,
      debugMode: isPreviewEnabled,
      targetOrigin: 'https://app.contentful.com'
    });
  },
  enforce: 'pre'
});
