<template>
  <LibErrorBoundary :component-name="mappedComponent" :child-props="item">
    <Suspense>
      <component
        :is="mappedComponent"
        v-if="mappedComponent"
        :item="item"
        v-bind="additionalProps"
      />
      <LibResolveError v-else-if="isEntryResolveError(item)" :item="item" />
      <div v-else-if="!isEntryNotAvailableInLanguageError(item)">
        Could not map <b>{{ contentTypeId }}</b>
      </div>
    </Suspense>
  </LibErrorBoundary>
</template>

<script setup lang="ts">
import type { Entry } from 'contentful';
import componentMap from './mapping';
import type { CONTENT_TYPE } from '~/lib/ContentfulService';
import {
  type ContentfulEntryNotResolvableError,
  isEntryResolveError
} from '~/utils/guards/isEntryResolveError';
import type { ContentfulEntryNotAvailableInLanguageError } from '~/utils/guards/isEntryNotAvailableInLanguageError';
import { isEntryNotAvailableInLanguageError } from '~/utils/guards/isEntryNotAvailableInLanguageError';
import { isDefined } from '~/utils/guards/isDefined';

interface Props {
  item:
    | Entry<unknown>
    | ContentfulEntryNotResolvableError
    | ContentfulEntryNotAvailableInLanguageError;
}

const props = defineProps<Props>();

const contentTypeId = computed(() => {
  if (
    isEntryResolveError(props.item) ||
    isEntryNotAvailableInLanguageError(props.item)
  ) {
    return;
  }

  return props.item?.sys?.contentType?.sys?.id as CONTENT_TYPE | undefined;
});

const mappedComponent = computed(() =>
  contentTypeId.value ? componentMap[contentTypeId.value] : undefined
);

const getColorMode = computed(() => {
  if (
    !(
      isEntryResolveError(props.item) ||
      isEntryNotAvailableInLanguageError(props.item)
    ) &&
    'fields' in props.item &&
    typeof props.item.fields === 'object' &&
    isDefined(props.item.fields) &&
    'theme' in props.item.fields
  ) {
    return props.item.fields.theme;
  }
});

const additionalProps = computed(() => {
  if (typeof getColorMode.value === 'string') {
    return { colorMode: getColorMode.value };
  }
  return {};
});
</script>
