import { type CustomAttributeV2Fragment } from '~/lib/Shop/generated/schema';

export type CustomAttributeV2_AttributeValue_Fragment = {
  __typename: 'AttributeValue';
  value: string;
  code: string;
  label: string;
};

export function isAttributeValue(
  p: CustomAttributeV2Fragment | undefined
): p is CustomAttributeV2_AttributeValue_Fragment {
  return p?.__typename === 'AttributeValue';
}
