import type { Entry, Asset } from 'contentful';
import { isArray } from './guards/isArray';
import { isDefined } from './guards/isDefined';
import { isEntryOrAsset } from './guards/isEntryOrAsset';
import type { EntryWithLinkData } from '~/lib/ContentfulService';
import type { EntryLink } from '~/utils/guards/isEntryLink';
import { isEntryLink } from '~/utils/guards/isEntryLink';
import { Tracer } from '~/lib/Tracing';
import {
  isEntryResolveError,
  type ContentfulEntryNotResolvableError
} from '~/utils/guards/isEntryResolveError';
import {
  isEntryNotAvailableInLanguageError,
  type ContentfulEntryNotAvailableInLanguageError
} from '~/utils/guards/isEntryNotAvailableInLanguageError';
import { cloneDeep } from '~/utils/cloneDeep';

export type ResolvedEntryTypes =
  // TODO: encapsulate the `Entry` type and always remove methods.
  | Omit<Entry<unknown>, 'toPlainObject' | 'update'>
  | EntryLink
  | Asset
  | ContentfulEntryNotResolvableError
  | ContentfulEntryNotAvailableInLanguageError;

/**
 * Resolves the given entry recursive with the given linked entries.
 *
 * @param data The data to resolve.
 * @param removeUnresolvedLinksAndErrors
 */
export function resolveEntry<T extends ResolvedEntryTypes>(
  data: EntryWithLinkData<T>,
  removeUnresolvedLinksAndErrors = false
): T {
  const tracer = new Tracer('utils');

  return tracer.withSyncSpan('resolveEntry', {}, () => {
    data = cloneDeep(data);
    return walk(
      data.entry as Walkable,
      data,
      removeUnresolvedLinksAndErrors
    ) as T;
  });
}

function walk<T extends Walkable>(
  value: Walkable,
  data: EntryWithLinkData<ResolvedEntryTypes>,
  removeUnresolvedLinksAndErrors = false,
  cache: Record<string, Entry<unknown> | Asset | undefined> = {}
): T {
  if (isEntryLink(value)) {
    const entry = data.linkedEntries[value.sys.id];

    if (entry) {
      return walk(
        entry as Walkable,
        data,
        removeUnresolvedLinksAndErrors,
        cache
      );
    }

    if (removeUnresolvedLinksAndErrors) {
      return undefined as unknown as T;
    }

    const errorEntry = data.errors[value.sys.id];

    if (errorEntry) {
      return errorEntry as unknown as T;
    }

    return value as T;
  }

  if (
    ((isEntryResolveError(value) as boolean) ||
      (isEntryNotAvailableInLanguageError(value) as boolean)) &&
    removeUnresolvedLinksAndErrors
  ) {
    return undefined as unknown as T;
  }

  if (isEntryOrAsset(value)) {
    if (!cache[value.sys.id]) {
      cache[value.sys.id] = value;

      if (typeof value.fields === 'object' && value.fields) {
        value.fields =
          walk(value.fields, data, removeUnresolvedLinksAndErrors, cache) ?? {};
      }
    }

    return cache[value.sys.id] as T;
  }

  if (isArray(value)) {
    return value.map((item) =>
      isWalkable(item)
        ? walk(item, data, removeUnresolvedLinksAndErrors, cache)
        : item
    ) as T;
  }

  for (const key in value) {
    const val = value[key];

    if (isWalkable(val)) {
      value[key] = walk(val, data, removeUnresolvedLinksAndErrors, cache);
    }
  }

  return value as T;
}

type Walkable =
  | Partial<Record<string, unknown>>
  | unknown[]
  | EntryLink
  | Entry<unknown>
  | Asset;

function isWalkable(value: unknown): value is Walkable {
  return (
    isDefined(value) &&
    (isEntryOrAsset(value) ||
      isEntryLink(value) ||
      isArray(value) ||
      typeof value === 'object')
  );
}
