import { type CustomAttributeV2Fragment } from '~/lib/Shop/generated/schema';

export type CustomAttributeV2_AttributeSelectedOptions_Fragment = {
  __typename: 'AttributeSelectedOptions';
  code: string;
  label: string;
  selected_options: Array<{
    __typename?: 'AttributeSelectedOption';
    label: string;
    value: string;
    uid: string;
  } | null>;
};

export function isAttributeSelectedOptions(
  p: CustomAttributeV2Fragment | undefined
): p is CustomAttributeV2_AttributeSelectedOptions_Fragment {
  return p?.__typename === 'AttributeSelectedOptions';
}
