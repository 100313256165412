import { isNavigationFailure } from '~/utils/analytics/isNavigationFailure';

declare global {
  type EventInfoModel = {
    category: string;
    action: string;
    label: string;
  };

  type AnalyticsEventModel =
    | {
        event: 'customEvent' | string;
        eventInfo: EventInfoModel;
      }
    | object;
}

export default defineNuxtPlugin({
  name: 'analytics',
  setup() {
    const router = useRouter();
    const { $shopStore, $localeSlugMap } = useNuxtApp();
    const { user } = useOidcAuth();

    // inspired by https://github.com/gtm-support/vue-gtm/blob/main/src/index.ts
    router.afterEach((to, from, failure) => {
      // duplicate route navigation? ignore.
      if (to.fullPath === from.fullPath) {
        return;
      }
      if (typeof to.name !== 'string') {
        return;
      }

      if (
        isNavigationFailure(failure, 4 /* NAVIGATION_ABORTED */) ||
        isNavigationFailure(failure, 8 /* NAVIGATION_CANCELLED */)
      ) {
        return;
      }

      // inspired by https://github.com/MatteoGabriele/vue-gtag/blob/master/src/add-routes-tracker.js
      return nextTick(() => {
        analytics.pushToDataLayer({ page_title: document.title });
        analytics.pushToDataLayer({ page_path: to.fullPath });
        analytics.pushToDataLayer({ ecom_region: $shopStore.value });
        analytics.pushToDataLayer({
          user_id: user?.value?.claims?.oid ?? 'null'
        });
      });
    });

    const analytics = {
      pushToDataLayer(event: AnalyticsEventModel) {
        window.dataLayer?.push(event);
      },
      getReferrer: () => {
        // this seeems to be undocumented. Probably unstable?
        // @see https://stackoverflow.com/questions/53788975/vue-router-how-to-get-previous-page-url
        return router.options.history.state.back?.toString();
      }
    };

    watch(
      [() => $localeSlugMap.value?.en],
      (next, prev) => {
        const nextValue = next?.[0];
        if (prev?.[0] === nextValue) {
          return;
        }
        if (nextValue) {
          analytics?.pushToDataLayer({
            page_path_en: buildUrlString('en', nextValue)
          });
        }
      },
      { immediate: true }
    );

    return {
      provide: {
        analytics
      }
    };
  }
});
