// @ts-nocheck

import { definePreset } from '@primeuix/themes';
import Lara from '@primeuix/themes/lara';

const colorAzureRadiance100: string = '#0094ff';
const colorScienceBlue100: string = '#006bb8';
const colorMineShaft100: string = '#222';
const colorMillionGrey100: string = '#999';
const colorLighthouse100: string = '#f4f4f4';

const colorStratos100: string = '#001643';
const colorBlack100: string = '#000000';
const colorBlack20: string = '#00000033';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorBlack10: string = '#0000001A';
const colorWhite100: string = '#fff';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorBaslerOrange: string = '#f59d21';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorBaslerOrangeDarker: string = '#d17f06';
const colorBaslerOrangeRed: string = '#ff5252';

const colorTopolea100: string = '#180038';

const BaslerPreset = definePreset(Lara, {
  semantic: {
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}'
    },
    focusRing: {
      width: '2px',
      offset: '1px',
      color: colorAzureRadiance100,
      ringShadow: '0 0 0 0.1rem ' + colorAzureRadiance100
    },
    colorScheme: {
      light: {
        primary: {
          color: colorAzureRadiance100,
          inverseColor: colorWhite100,
          hoverColor: colorScienceBlue100,
          activeColor: colorAzureRadiance100
        },
        formField: {
          borderColor: colorMillionGrey100,
          hoverBorderColor: colorBlack20,
          focusBorderColor: colorAzureRadiance100,
          invalidBorderColor: colorBaslerOrangeRed
        }
      },
      dark: {
        primary: {
          color: colorWhite100,
          inverseColor: colorBlack100,
          hoverColor: colorLighthouse100,
          activeColor: colorLighthouse100
        },
        formField: {
          borderColor: colorWhite100,
          hoverBorderColor: colorLighthouse100,
          focusBorderColor: colorAzureRadiance100,
          invalidBorderColor: colorBaslerOrangeRed
        }
      }
    }
  },
  components: {
    checkbox: {
      background: 'none',
      checkedBackground: colorAzureRadiance100,
      checkedHoverBackground: colorScienceBlue100,
      checkedBorderColor: colorAzureRadiance100,
      checkedHoverBorderColor: colorScienceBlue100,
      iconSize: '14px',
      iconCheckedColor: colorWhite100,
      iconCheckedHoverColor: colorWhite100,
      css: () => `
      .theme-dark label.p-label {
        color: ${colorWhite100}
      }
      label.p-label {
        font-size: 0.9rem;
        line-height: 1.1rem;
      }
      `,
      colorScheme: {
        light: {
          borderColor: colorMillionGrey100,
          hoverBorderColor: colorBlack20
        },

        dark: {
          borderColor: colorWhite100,
          hoverBorderColor: colorAzureRadiance100
        }
      }
    },
    radiobutton: {
      background: 'none',
      checkedBackground: 'none',
      checkedHoverBackground: 'none',
      checkedBorderColor: colorAzureRadiance100,
      checkedHoverBorderColor: colorScienceBlue100,
      iconSize: '12px',
      iconCheckedColor: colorAzureRadiance100,
      iconCheckedHoverColor: colorScienceBlue100,
      css: () => `
      .theme-dark label.p-label {
        color: ${colorWhite100}
      }
      label.p-label {
        font-size: 0.9rem;
        line-height: 1.1rem;
      }
      `,
      colorScheme: {
        light: {
          borderColor: colorMillionGrey100,
          borderHoverColor: colorBlack20
        },

        dark: {
          borderColor: colorWhite100,
          borderHoverColor: colorMillionGrey100
        }
      }
    },
    select: {
      filledBackground: colorLighthouse100,
      filledFocusBackground: colorLighthouse100,
      filledHoverBackground: colorLighthouse100,
      borderRadius: '8px',
      focusBorderColor: colorAzureRadiance100,
      hoverBorderColor: colorAzureRadiance100,
      focusRingShadow: '0 0 0 0.1rem ' + colorAzureRadiance100,
      dropdownWidth: '30px',
      optionSelectedBackground: colorAzureRadiance100,
      optionSelectedColor: colorWhite100,
      optionSelectedFocusBackground: colorScienceBlue100,
      optionSelectedFocusColor: colorWhite100,
      optionFocusBackground: colorScienceBlue100,
      optionFocusColor: colorWhite100,
      optionBorderRadius: '8px',
      optionPadding: '5px 10px',
      overlayBorderRadius: '8px',
      overlayBackground: colorLighthouse100,
      listHeaderPadding: '0.625rem 1rem 0.625rem 1rem',
      colorScheme: {
        dark: {
          color: colorWhite100,
          dropdownColor: colorWhite100,
          filledFocusBackground: colorStratos100,
          filledHoverBackground: colorStratos100
        }
      },
      css: () => `
      .p-variant-filled {
        border: 2px solid ${colorLighthouse100}
      }
      .page-wrapper--dark-purple .theme-dark .p-select.p-variant-filled,
      .page-wrapper--dark-purple .theme-dark .p-select.p-variant-filled:not(.p-disabled):hover,
      .page-wrapper--dark-purple .theme-dark .p-select.p-variant-filled:not(.p-disabled):focus {
        background: ${colorTopolea100};
      }
      `
    },
    slider: {
      trackBackground: colorBlack20,
      rangeBackground: colorAzureRadiance100,
      trackSize: '7px',
      handleContentHoverBackground: colorWhite100,
      handleContentWidth: '14px',
      handleContentHeight: '14px',
      css: () => `
      .p-invalid .p-slider-range {
        background-color: ${colorBaslerOrangeRed};
      }
      `
    },
    floatlabel: {
      color: colorMillionGrey100,
      focusColor: colorMineShaft100,
      colorScheme: {
        dark: {
          activeColor: colorWhite100,
          focusColor: colorLighthouse100,
          onActiveBackground: colorStratos100
        }
      },
      css: () => `
      .p-floatlabel-on:has(.p-inputwrapper-filled) label {
        z-index:1000;
      }
      .page-wrapper--dark-purple .theme-dark .p-floatlabel-on:has(.p-inputwrapper-filled) label {
        background-color: ${colorTopolea100};
      }
      `
    },

    inputtext: {
      borderColor: colorBlack100,
      hoverBorderColor: colorMineShaft100,
      focusBorderColor: colorAzureRadiance100,
      focusRingShadow: '0 0 0 0.1rem ' + colorAzureRadiance100,
      placeholderColor: colorMillionGrey100,
      disabledBackground: colorLighthouse100,
      invalidBorderColor: colorBaslerOrangeRed,
      css: ({ dt }) => `
      .p-inputtext.p-invalid {
        box-shadow: 0 0 0 0.1rem ${colorBaslerOrangeRed};
      }
      .p-search-box-form .p-iconfield .p-inputtext:not(:last-child) {
        padding-inline-end: calc((${dt(
          'searchbox.formfieldpaddingxmobile'
        )} * 2) + (${dt('searchbox.iconsizemobile')}));
      }
      @media(min-width: 1024px) {
        .p-search-box-form .p-iconfield .p-inputtext:not(:last-child) {
        padding-inline-end: calc((${dt(
          'searchbox.formfieldpaddingx'
        )} * 2) + (${dt('searchbox.iconsize')}));
        }
      }
      .p-search-box-form .p-inputtext.p-search-box {
        padding: 9px 8px 9px 20px;
        border-radius: 100px;
        border-width: 3px;
        box-shadow: 0 52px 21px rgba(0, 0, 0, 0.01), 0 29px 18px rgba(0, 0, 0, 0.05), 0 13px 13px rgba(0, 0, 0, 0.09), 0 3px 7px rgba(0, 0, 0, 0.1);
        font-size: 16px;
      }
      @media(min-width: 1024px) {
        .p-search-box-form .p-inputtext.p-search-box {
          padding: 20px 16px 20px 32px;
          font-size: 28px;
        }
      }

      .theme-dark .p-search-box-form .p-inputtext.p-search-box {
        color: ${colorWhite100};
        border-color: ${colorWhite100};
        background: none;
      }
      .theme-dark .p-inputtext::placeholder {
        color: ${colorWhite100};
      }
      `,
      colorScheme: {
        dark: {
          background: 'none',
          color: colorWhite100,
          borderColor: colorWhite100
        }
      }
    },
    inputnumber: {
      buttonWidth: '20px'
    },
    iconfield: {
      css: ({ dt }) => `
      .p-search-box-form .p-iconfield:has(.p-inputfield-lg) .p-inputicon {
        font-size: ${dt('searchbox.iconsizemobile')};
        width: ${dt('searchbox.iconsizemobile')};
        height: ${dt('searchbox.iconsizemobile')};
        margin-top: calc(-1*(${dt('searchbox.iconsizemobile')} / 2));
      }
      .p-search-box-form .p-iconfield .p-inputicon:last-child {
        inset-inline-end: ${dt('searchbox.formfieldpaddingxmobile')};
      }
      @media(min-width: 1024px) {
          .p-search-box-form .p-iconfield:has(.p-inputfield-lg) .p-inputicon {
          font-size: ${dt('searchbox.iconsize')};
          width: ${dt('searchbox.iconsize')};
          height: ${dt('searchbox.iconsize')};
          margin-top: calc(-1*(${dt('searchbox.iconsize')} / 2));
        }
        .p-search-box-form .p-iconfield .p-inputicon:last-child {
          inset-inline-end: ${dt('searchbox.formfieldpaddingx')};
        }
        .p-search-box-form .p-iconfield .p-inputicon .icon--small {
          font-size: ${dt('searchbox.iconsize')};
        }
      }
      `
    },
    tieredmenu: {
      borderRadius: '20px',
      listPadding: '20px',
      listGap: '2',
      itemPadding: '5px',
      itemGap: '2',
      itemFocusBackground: colorLighthouse100,
      itemActiveBackground: colorLighthouse100,
      css: () => `
      .p-tieredmenu-item-content {
        cursor:pointer;
        padding: 5px 10px;
      }
      `
    },
    css: () => `
      label.p-label {
        font-size: 0.9rem;
        line-height: 1.1rem;
      }
      .theme-dark input[type="search" i]::-webkit-search-cancel-button {
        color: ${colorWhite100}
      }
      `
  },
  extend: {
    searchbox: {
      iconsize: '2.25rem',
      iconsizemobile: '1.25rem',
      formfieldpaddingx: '2rem',
      formfieldpaddingxmobile: '1rem'
    }
  }
});

export default {
  preset: BaslerPreset,
  options: {
    prefix: 'p',
    darkModeSelector: '.theme-dark',
    cssLayer: false
  }
};
