import { acceptHMRUpdate, defineStore } from 'pinia';
import { isShowFlags } from './helper/isShowFlags';
import { useGeolocationCookie } from './useGeolocationCookie';
import { createCurrentCountryLabel } from './helper/createCurrentCountryLabel';
import { getClientLocale } from './helper/getClientLocale';
import type { LOCALE_CODE } from '~/lib/ContentfulService';
import type { GeolocationResponseModel } from '~/server/api/geolocation/index.post';

export type GeoinformationStoreState = {
  clientLocale: string;
  /**
   * The preferred iso country code.
   *
   * @example "SG"
   */
  preferredCountry: string | null;
  /**
   * The preferred locale based on the preferred country.
   *
   * @example "en-SG"
   */
  preferredLocale: string | null;
  detectedCountry: string | null;
  detectedLocale: LOCALE_CODE | null;
  /**
   * `true` if the api was called for geoinformation.
   * If geoinformation has already been store inside the cookie,
   * this remains `false`.
   */
  geolocationLoaded: boolean;
  /**
   * `true` if  *client side information* hase been loaded.
   */
  initiated: boolean;
};

/**
 * Can not be serialized, therefore not stored in state.
 */
let geolocationPromise: Promise<GeolocationResponseModel> | null = null;

export const useGeoinformationStore = defineStore('geoinformation', () => {
  const clientLocale = ref(getClientLocale());

  const preferredCountry = ref<string | null>(null);
  const preferredLocale = ref<string | null>(null);
  const detectedCountry = ref<string | null>(null);
  const detectedLocale = ref<LOCALE_CODE | null>(null);
  const geolocationLoaded = ref<boolean>(false);
  const initiated = ref<boolean>(false);

  const _state = {
    clientLocale,
    preferredCountry,
    preferredLocale,
    detectedCountry,
    detectedLocale,
    geolocationLoaded,
    initiated
  };

  const showCountryFlags = computed(() => {
    const { $resolvedLocale } = useNuxtApp();

    return isShowFlags({
      detectedCountry: detectedCountry.value,
      contentfulLocale: $resolvedLocale.value
    });
  });

  const currentRegionLabel = computed(() => {
    const { $resolvedLocale } = useNuxtApp();

    return createCurrentCountryLabel(
      preferredCountry.value ?? $resolvedLocale.value,
      $resolvedLocale.value,
      clientLocale.value
    );
  });

  async function ensureRedirectLocale(): Promise<LOCALE_CODE | undefined> {
    if (detectedLocale.value || geolocationLoaded.value) {
      return detectedLocale.value ?? undefined;
    }

    await loadGeolocation();

    return detectedLocale.value ?? undefined;
  }

  /**
   * Loads the geolocation if not already set.
   * The api endpoint is only called once.
   * Multiple calls to this function will not initate a new api request.
   */
  async function loadGeolocation(): Promise<void> {
    if (detectedCountry.value) {
      return;
    }

    if (geolocationPromise) {
      await geolocationPromise;

      return;
    }

    try {
      geolocationPromise = $fetch<GeolocationResponseModel>(
        '/api/geolocation',
        {
          ...DEFAULT_FETCH_OPTIONS,
          method: 'post'
        }
      );

      const data = await geolocationPromise;

      if (!data.geolocation) {
        // failed to detect geolocation
        return;
      }

      detectedLocale.value = data.geolocation.detectedLocale ?? null;
      detectedCountry.value = data.geolocation.detectedCountry ?? null;

      const cookie = useGeolocationCookie();
      cookie.value = {
        ...cookie.value,
        detectedCountry: data.geolocation.detectedCountry,
        detectedLocale: data.geolocation.detectedLocale
      };
    } catch (error) {
      useLogger().error(
        'loadGeolocation',
        'failed to load geolocation: ',
        error
      );
    } finally {
      geolocationLoaded.value = true;
    }
  }

  function updatePreferredSettings(preferredSettings: {
    locale?: string;
    country?: string;
  }): void {
    const cookie = useGeolocationCookie();

    preferredLocale.value = preferredSettings.locale ?? null;
    preferredCountry.value = preferredSettings.country ?? null;

    cookie.value = {
      ...cookie.value,
      preferredLocale: preferredSettings.locale,
      preferredCountry: preferredSettings.country
    };
  }

  async function init() {
    clientLocale.value = getClientLocale();

    const cookie = useGeolocationCookie();
    preferredCountry.value = cookie.value?.preferredCountry ?? null;
    preferredLocale.value = cookie.value?.preferredLocale ?? null;
    detectedCountry.value = cookie.value?.detectedCountry ?? null;
    detectedLocale.value = cookie.value?.detectedLocale ?? null;

    await loadGeolocation();

    initiated.value = true;
  }

  return {
    _state,
    preferredCountry,
    preferredLocale,
    detectedCountry,
    detectedLocale,
    geolocationLoaded,
    initiated,
    clientLocale,
    init,
    updatePreferredSettings,
    loadGeolocation,
    ensureRedirectLocale,
    currentRegionLabel,
    showCountryFlags
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useGeoinformationStore, import.meta.hot)
  );
}
