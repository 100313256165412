import type { MoneyFragment } from '~/lib/Shop/generated/schema';
import { cloneDeep } from '~/utils/cloneDeep';

export function clonePriceWithValue(
  price: MoneyFragment | null | undefined,
  newPrice?: number | null | undefined
): MoneyFragment | null | undefined {
  if (!newPrice) {
    return;
  }

  const clonedPrice = cloneDeep(price);
  if (clonedPrice?.value) {
    clonedPrice.value = newPrice;
  }

  return clonedPrice;
}
