import { parse } from 'accept-language-parser';

/**
 * Determines the client locale. If no locale could be determined, `en` is
 * returned.
 *
 * Uses {@link navigator.language} on client side and `Accept-Language`
 * Headers on server side.
 */
export function getClientLocale(): string {
  if (import.meta.client) {
    return navigator.language;
  }

  const logger = useLogger();
  const acceptLanguage = useRequestHeaders()?.['Accept-Language'];
  if (acceptLanguage) {
    try {
      return parse(acceptLanguage)?.[0]?.code ?? 'en';
    } catch (e) {
      logger.error('failed to parse Accept-Language header', acceptLanguage, e);

      return 'en';
    }
  }

  return 'en';
}
