import {
  type CommonProductFragment,
  type ConfigurableProduct,
  type CustomAttribute,
  type CustomAttributeV2Fragment,
  type ProductDetailFragment,
  type SimpleProduct
} from '~/lib/Shop/generated/schema';
import { isDefined } from '~/utils/guards/isDefined';

export function extractCustomAttributes(
  product:
    | ConfigurableProduct
    | SimpleProduct
    | CommonProductFragment
    | ProductDetailFragment
    | undefined
): CustomAttributeV2Fragment[] {
  if (!isDefined(product)) {
    return [];
  }

  if (isDefined((product as CommonProductFragment)?.custom_attributesV2)) {
    return (
      (product as CommonProductFragment)?.custom_attributesV2?.items ?? []
    ).filter(isDefined) as CustomAttributeV2Fragment[];
  }

  if (isDefined((product as ConfigurableProduct).custom_attributes)) {
    return transformCustomAttributesToV2(
      ((product as ConfigurableProduct)
        .custom_attributes as CustomAttribute[]) ?? []
    );
  }

  return [];
}

function transformCustomAttributesToV2(
  customAttributes: CustomAttribute[]
): CustomAttributeV2Fragment[] {
  return customAttributes.map((attr) => {
    const attributeCode = attr.attribute_metadata?.code ?? '';
    const attributeLabel = attr.attribute_metadata?.label ?? '';

    if (
      isDefined(attr.entered_attribute_value) &&
      isDefined(attr.entered_attribute_value?.value)
    ) {
      return {
        __typename: 'AttributeValue',
        value: attr.entered_attribute_value.value,
        code: attributeCode,
        label: attributeLabel
      };
    }
    if (
      isDefined(attr.selected_attribute_options) &&
      isDefined(attr.selected_attribute_options.attribute_option)
    ) {
      return {
        __typename: 'AttributeSelectedOptions',
        code: attributeCode,
        label: attributeLabel,
        value: undefined,
        selected_options: attr.selected_attribute_options.attribute_option
          .filter(isDefined)
          .map((option) => {
            return {
              __typename: 'AttributeSelectedOption',
              label: option.label,
              value: option.value,
              uid: option.uid
            };
          })
      };
    }
  }) as CustomAttributeV2Fragment[];
}
